/* exported AssistMessageHandler */
function AssistMessageHandler(usePostMessage) {
    var _self = this;
    var destinationWindow;
    var requestId = 1;
    var requestCallbacks = {};
    var handlers = {};
    var knownOrigins = {};
    
    function sendMessage(messageType, data, requestId) {
        var message = {
            "type": messageType,
            "data": data || "",
            "requestId": requestId || ""
        };

        if (!destinationWindow || destinationWindow.closed) {
            return;
        }

        if (usePostMessage) {
            destinationWindow.postMessage(JSON.stringify(message), "*");
        }
        else {
            destinationWindow.handleDirectMessage(message, window);
        }
    }

    function receiveMessage(event) {
        if (checkMessageOrigin(event)) {
            var messageDetails = JSON.parse(event.data);
            handleReceivedMessage(messageDetails, event.source);
        }
    }

    function checkMessageOrigin(event) {
        // If the user has navigated away from LA, there may not be an event source.
        // In that case, only allow the origin if we have allowed it before.
        if (event.source && (event.source == destinationWindow)) {
            knownOrigins[event.origin] = true;
        }
        return knownOrigins[event.origin];
    }

    function handleReceivedMessage(messageDetails, sourceWindow) {
        var handler = handlers[messageDetails.type];

        if (!handler) {
            console.warn("No handler for type", messageDetails.type);
            return;
        }

        handler(messageDetails, sourceWindow);
    }
    
    _self.messages = {
        RESPONSE: "response",
        CHECK_INIT_RUN: "checkInitRun",
        INIT: "init",
        RECONNECT: "reconnect",
        END_CALL: "endCall",
        CONSOLE_LOG: "consoleLog",
        HANDLE_UNLOAD: "handleUnload",
        HANDLE_RELOAD: "handleReload",
        ON_WEBCAM_USE_ACCEPTED: "onWebcamUseAccepted",
        SHOW_VIDEO: "showVideo",
        ERROR: "error",
        CONNECTION_QUALITY_CHANGED: "connectionQualityChanged",
        DISPLAY_MODAL: "displayModal",
        SET_LOCAL_MEDIA_ENABLED: "setLocalMediaEnabled",
        SESSION_CREATION_FAILURE: "sessionCreationFailure",
        CLOSE_POPUP: "closePopup"
    };
    
    _self.addHandler = function(messageType, handlerFunction) {
        handlers[messageType] = handlerFunction;
    };
    
    _self.send = function(messageType, data) {
        sendMessage(messageType, data);
    };
    
    _self.respond = function(requestId, data) {
        sendMessage(_self.messages.RESPONSE, data, requestId);
    };
    
    _self.sendMessageGetResponse = function(type, data, callback) {
        requestCallbacks[requestId] = callback;
        sendMessage(type, data, requestId++);
    }
    
    _self.setDestinationWindow = function (newDestinationWindow) {
        destinationWindow = newDestinationWindow;
    };
    
    _self.detach = function () {
        if (usePostMessage) {
            window.removeEventListener("message", receiveMessage, false);
        }
    };
    
    // Default response handler
    _self.addHandler(_self.messages.RESPONSE, function(messageDetails) {
        var callback = requestCallbacks[messageDetails.requestId];
        delete requestCallbacks[messageDetails.requestId];
        if (callback) {
            callback(messageDetails.data);
        }
    });
    
    if (usePostMessage) {
        window.addEventListener("message", receiveMessage, false);
    }
    else {
        window.handleDirectMessage = function(messageDetails, sourceWindow) {
            setTimeout(function() {
                handleReceivedMessage(messageDetails, sourceWindow)
            },0);
        };
    }
}

module.exports = AssistMessageHandler;